import { httpService, getPaginationQuery } from ".";

/**
 * /get-cargo-to-load
 * /get-cargo-to-confirm
 * /get-cargo-to-unload
 * /get-cargo-to-deliver
 *
 * @param {*} url
 * @returns
 */
export async function getCargoData(url = "", query) {
  return (
    url &&
    (await httpService.get(`/cargo-action/${url}`, {
      params: query,
    }))
  );
}

export async function get_cargo_by_way(way_id) {
  return await httpService.get(`/cargo-action/get-cargo-by-way/${way_id}`);
}

export async function get_inventory_data(data) {
  return await httpService.post(
    `/cargo-action/get-shipping-inventory?${getPaginationQuery()}`,
    data
  );
}

export async function get_sign_cargo_data(url, data) {
  return await httpService.post(`/cargo-action/${url}`, data);
}

export async function delete_sign_query(id) {
  return await httpService.post(`/sign/delete-query`, { _id: id });
}

export async function get_waybill_tracking(waybill_number) {
  return await httpService.get(
    `/frontend/get-waybill-tracking?waybill_number=${waybill_number}`
  );
}

export async function get_waybill_log(waybill_number) {
  return await httpService.get(
    `/waybill/get-log?waybill_number=${waybill_number}`
  );
}

export async function export_waybills(way_id) {
  return await httpService.post(
    "/way/export-waybill",
    { way_id: way_id },
    {
      responseType: "blob",
    }
  );
}

export async function export_sign_query(data) {
  return await httpService.post("/sign/export-query", data, {
    responseType: "blob",
  });
}

/**
 * for multiple records processing
 *
 * cargo process
 * -> loading -> confirm -> unload -> deliver or sign
 * status
 * CREATED -> LOADED -> CONFIRMED -> UNLOADED -> DELIVERED -> SIGNED
 *
 * @param {*} data
 * @returns
 */
export async function cargo_loading(data = {}) {
  return await httpService.post(`/cargo-action/manual-cargo-loading`, data);
}

export async function add_sign_query(data = {}) {
  return await httpService.post(`/sign/create`, data);
}

export async function update_sign_query(data = {}) {
  return await httpService.post(`/sign/update`, data);
}

export async function edit_cargo_loading(data = {}) {
  return await httpService.post(
    `/cargo-action/manual-edit-cargo-loading`,
    data
  );
}

// undo-loading means the loaded item was took back to the warehouse and the waybill
// item will not process with that container
export async function cargo_undo_loading(data) {
  return await httpService.post(`/cargo-action/cargo-undo-loading`, data);
}

/**
 * cargo unloading process
 * @param {*} data
 * @returns
 */
export async function cargo_unloading(data) {
  return await httpService.post(`/cargo-action/cargo-unloading`, data);
}

export async function cargo_undo_unloading(data) {
  return await httpService.post(`/cargo-action/cargo-undo-unloading`, data);
}

export async function sign_confirmation(data) {
  return await httpService.post(`/cargo-action/sign-confirmation`, data);
}

/**
 * for single records updating,
 * loading sub item
 * @param {} data
 * @returns
 */
export async function cargo_loading_sub_item(data) {
  return await httpService.post(`/cargo-action/load-sub-item`, data);
}

export async function remove_sign_query_image(data) {
  return await httpService.post(`/sign/remove_image`, data);
}

export async function cargo_unloading_sub_item(data) {
  return await httpService.post(`/cargo-action/unload-sub-item`, data);
}
