import * as common from "../type";

const types = {
  SET_CONFIRMATION_LIST: "SET_CONFIRMATION_LIST",
  SET_SIGN_QUERY_LIST: "SET_SIGN_QUERY_LIST",
  SET_CURRENT_ROW_TO_SIGN_CONFIRM: "SET_CURRENT_ROW_TO_SIGN_CONFIRM",
  SET_DELETE_SIGN_QUERY: "SET_DELETE_SIGN_QUERY",
  SET_SIGN_QUERY_DETAIL: "SET_SIGN_QUERY_DETAIL",
};

export const initialState = {
  isLoading: false,
  errorMessage: "",
  confirmation_list: [],
  query_list: [],
  show_detail_dialog: false,
  current_row: null,
};

const _getCommonState = (state) => ({
  ...state,
  isLoading: false,
  errorMessage: "",
});

const Index = (state = initialState, action) => {
  switch (action.type) {
    case common.START_LOADING:
      return {
        ..._getCommonState(state),
        isLoading: true,
      };
    case common.STOP_LOADING:
      return {
        ..._getCommonState(state),
      };
    case common.SET_ERROR_MESSAGE:
      return {
        ..._getCommonState(state),
        errorMessage: action.payload,
      };
    /**
     * Sign confirmation
     */
    case types.SET_CONFIRMATION_LIST:
      return {
        ..._getCommonState(state),
        confirmation_list: action.payload,
      };
    case types.SET_DELETE_SIGN_QUERY:
      return {
        ..._getCommonState(state),
        current_row: action.payload.current_row,
        delete_dialog: action.payload.delete_dialog,
      };
    /**
     * Sign query list
     */
    case types.SET_SIGN_QUERY_LIST:
      return {
        ..._getCommonState(state),
        query_list: action.payload,
      };

    case types.SET_SIGN_QUERY_DETAIL:
      return {
        ..._getCommonState(state),
        show_detail_dialog: action.payload.show_detail_dialog,
        current_row: action.payload.current_row,
      };

    /**
     * set current row
     */
    case types.SET_CURRENT_ROW_TO_SIGN_CONFIRM:
      return {
        ..._getCommonState(state),
        current_row: action.payload,
      };
    default:
      return state;
  }
};

export default Index;

/**
 * action
 */

export const start_loading = () => ({
  type: common.START_LOADING,
});

export const stop_loading = () => ({
  type: common.STOP_LOADING,
});

export const set_error_message = (message = "") => ({
  type: common.SET_ERROR_MESSAGE,
  payload: message,
});

export const set_to_delete_sign_query = (data) => ({
  type: types.SET_DELETE_SIGN_QUERY,
  payload: data,
});

export const set_sign_query_detail = (data) => ({
  type: types.SET_SIGN_QUERY_DETAIL,
  payload: data,
});

/**
 * Sign confirmation
 */
export const set_confirmation_list = (data = []) => ({
  type: types.SET_CONFIRMATION_LIST,
  payload: data,
});

/**
 * Sign query
 */
export const set_sign_query_list = (data = []) => ({
  type: types.SET_SIGN_QUERY_LIST,
  payload: data,
});

/**
 * set current row
 */
export const set_current_row = (data) => ({
  type: types.SET_CURRENT_ROW_TO_SIGN_CONFIRM,
  payload: data,
});
