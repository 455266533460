import { httpService } from ".";
import { getPaginationQuery } from ".";
import axios from "axios";
import { BASE_URL } from "./api";
import { GET_STORED_ACCESS_TOKEN } from "../util/storage";

/**
 * waybill list raw
 */
export async function get_way_bill_list() {
  return await httpService.get(`/waybill/get-all?${getPaginationQuery()}`);
}

export async function get_waybill_by_number(waybill_no, status) {
  return await httpService.get(
    `/waybill/find-one/${waybill_no}?status=${status}`
  );
}

export async function get_waybill_by_number_no_status(waybill_no) {
  return await httpService.get(`/waybill/find-one/${waybill_no}`);
}

/**
 * requesting and canceling waybill number
 */
export async function request_waybill_number() {
  return await httpService.get(`/waybill/request-waybill-number`);
}

export async function cancel_waybill_number(data) {
  return await httpService.post(`/waybill/cancel-waybill-number`, data);
}

export async function delete_waybill(id) {
  return await httpService.delete(`/waybill/${id}`);
}

/**
 * waybill list for shipping inventory
 */
export async function get_way_bill_list_shipping_inventory(data) {
  return await httpService.post(
    `/waybill/get-waybill-for-shipping-inventory?${getPaginationQuery()}`,
    data
  );
}

/**
 * waybill list raw for destination inventory
 */
export async function get_way_bill_list_for_destination_inventory(data) {
  return await httpService.post(
    `/waybill/get-waybill-for-destination-inventory?${getPaginationQuery()}`,
    data
  );
}

/**
 * waybill list for cargo action
 */
export async function get_cargo_way_bill_list(data) {
  return await httpService.post(`/waybill/get-waybill-for-cargo-action`, data);
}

/**
 *
 */
export async function sign_confirmation(data) {
  return await httpService.post(`/waybill/sign-confirmation`, data);
}

/**
 * calcuate volume
 */
export async function calculate_volume(data) {
  return await httpService.post("/waybills/calculate-volume", data);
}

/**
 * creating new way bill
 * @param {*} data
 */
export async function create_waybill_doc(data) {
  return await httpService.post("/waybill/create", data);
}

export async function check_freight_cost(data) {
  return await httpService.post("/waybills/check-freight-cost", data);
}

export async function assign_to_user(data) {
  return await httpService.post("/waybills/assign-to-user", data);
}

/**
 * express tracking
 */

export async function get_by_waybill(waybill) {
  return await httpService.get(`/waybills/${waybill}`);
}

export async function get_by_product_code(code, { waybill, related }) {
  let query = [];
  if (waybill) query.push("waybill=1");
  if (related) query.push("related=1");

  let endpoint = `/waybills/code/${code}?${query.join("&")}`;

  return await httpService.get(endpoint);
}

/**
 * by query for [sender_name,senderphone, reciver_name,receiver_phone]
 */
export async function inquire(data) {
  return await httpService.post(`/waybills/tracking`, data);
}

/**
 * waybill searching and filtering
 */
export async function search_and_filter(data) {
  return await httpService.post(
    `/waybill/search?${getPaginationQuery()}&total=true`,
    data
  );
}

export async function export_waybills(data) {
  return await httpService.post("/waybill/export-waybill", data, {
    responseType: "blob",
  });
  // httpService.get(`/waybill/export-waybill`)
}

export async function export_shippingInvenctory(data) {
  return await httpService.post(
    "/cargo-action/get-cargo-inventory-export",
    { status: data },
    {
      responseType: "blob",
    }
  );
}

/**
 * Sign waybill
 */
export async function get_sign_waybill_option() {
  return await httpService.get("/waybill/sign-confirmation-waybill");
}

/**
 * Get signed waybill
 */
export async function get_signed_waybill(data) {
  return await httpService.post(
    `/waybill/signed-waybill?${getPaginationQuery()}`,
    data
  );
}

//===========================================================================
// Orders
//===========================================================================

/**
 * order query
 */
export async function get_delivered_waybill_orders(force) {
  return await httpService.get(
    `/waybills/get-delivered-waybills${force ? "-update" : ""}`
  );
}

/**
 * Waybill Image upload
 */
export async function upload_waybill_image(waybill_number, form_data) {
  return await httpService.post(
    `/files/waybill-image?waybillnumber=${waybill_number}`,
    form_data
  );
}

export async function upload_waybill_images(waybill_number, form_data) {
  return await httpService.post(
    `/files/waybill-images?waybillnumber=${waybill_number}`,
    form_data
  );
}

/**
 * Waybill fetch image
 */

export async function fetch_waybill_image(file_name) {
  return await httpService.get(`/files/waybill-single-image/${file_name}`, {
    responseType: "blob",
  });
}

/**
 * Delete waybill image
 */
export async function delete_waybill_image(filename, waybillnumber, type) {
  return await httpService.delete(
    `files/waybill-image-delete-custom/?file_name=${filename}&waybill_id=${waybillnumber}&type=${type}`
  );
}

/**
 * Delete waybill
 */
export async function delete_multi_waybill(waybill_id_list) {
  return await axios.delete(`${BASE_URL}/waybill`, {
    data: { waybill_id_list },
    headers: { "x-access-token": GET_STORED_ACCESS_TOKEN },
  });
}
