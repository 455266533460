import * as common from "./type";

const types = {
  SET_BRANCH_TREE: "SET_BRANCH_TREE",
  UPDATE_BRANCH_TREE: "UPDATE_BRANCH_TREE",
  SET_DESTINATION: "SET_DESTINATION",
  SET_TO_DELETE_CLEARNCE: "SET_TO_DELETE_CLEARNCE",
  SET_TO_DELETE_ESTIMATED_TIME: "SET_TO_DELETE_ESTIMATED_TIME",
  /**
   * other TYPES
   */
  SET_ESITMATED_TIME: "SET_ESITMATED_TIME",
  SET_ITEM_TYPES: "SET_ITEM_TYPES",
  SET_CUSTOM_CLEARENCE: "SET_CUSTOM_CLEARENCE",
  SET_EXPRESS_TYPES: "SET_EXPRESS_TYPES",
  SET_SHIPPING_MODES: "SET_SHIPPING_MODES",
  SET_CARRIER: "SET_CARRIER",
  SET_PAYMENT_METHOD: "SET_PAYMENT_METHOD",
  SET_TRANSPORTATIONS: "SET_TRANSPORTATIONS",
  SET_PROBLEM_TYPES: "SET_PROBLEM_TYPES",

  SET_ADDRESS_BOOK: "SET_ADDRESS_BOOK",
  SET_EMPLOYEE: "SET_EMPLOYEE",
  SET_POSITION: "SET_POSITION",
  SET_COLLECTION_TYPES: "SET_COLLECTION_TYPES",
  SET_CONTAINER_LIST: "SET_CONTAINER_LIST",
};

export const initialState = {
  isLoading: false,
  errorMessage: "",

  branchTree: [],

  /** tree */
  branchParent: [],
  branchChild: [],

  destinations: {},
  /**
   * other maintenance
   */
  itemtypes: [],
  expresstypes: [],
  shipping_modes: [],
  carriers: [],
  payment_methods: [],
  transportations: [],
  problem_types: [],
  currency: [],
  customClearence: [],
  employee_list: [],
  address_books: [],
  esimatedTimes: [],
  to_delete_clearence: false,
  to_delete_estimated_time: false,

  positions: [],
  collection_types: [],

  container_list: [],
};

/**
 * Reducer
 */

const Index = (state = initialState, action) => {
  switch (action.type) {
    case common.START_LOADING:
      return {
        ..._getCommonState(state),
        isLoading: true,
      };
    case common.STOP_LOADING:
      return {
        ..._getCommonState(state),
      };
    case common.SET_ERROR_MESSAGE:
      return {
        ..._getCommonState(state),
        errorMessage: action.payload,
      };

    case types.SET_BRANCH_TREE:
      return {
        ..._getCommonState(state),
        branchChild: action.child,
        branchParent: action.parent,

        // branchTree: action.payload,
      };
    case types.UPDATE_BRANCH_TREE:
      return {
        ..._getCommonState(state),
      };
    case types.SET_DESTINATION:
      return {
        ..._getCommonState(state),
        destinations: action.payload,
      };

    case types.SET_TO_DELETE_CLEARNCE:
      return {
        ..._getCommonState(state),
        to_delete_clearence: action.payload,
      };

    case types.SET_TO_DELETE_ESTIMATED_TIME:
      return {
        ..._getCommonState(state),
        to_delete_estimated_time: action.payload,
      };
    /**
     * other
     */
    case types.SET_CURRENCY:
      return {
        ..._getCommonState(state),
        currency: action.payload,
      };
    case types.SET_ITEM_TYPES:
      return {
        ..._getCommonState(state),
        itemtypes: action.payload,
      };

    case types.SET_EXPRESS_TYPES:
      return {
        ..._getCommonState(state),
        expresstypes: action.payload,
      };
    case types.SET_SHIPPING_MODES:
      return {
        ..._getCommonState(state),
        shipping_modes: action.payload,
      };
    case types.SET_CARRIER:
      return {
        ..._getCommonState(state),
        carriers: action.payload,
      };
    case types.SET_ESITMATED_TIME:
      return {
        ..._getCommonState(state),
        estimatedTimes: action.payload,
      };
    case types.SET_PAYMENT_METHOD:
      return {
        ..._getCommonState(state),
        payment_methods: action.payload,
      };
    case types.SET_TRANSPORTATIONS:
      return {
        ..._getCommonState(state),
        transportations: action.payload,
      };
    case types.SET_PROBLEM_TYPES:
      return {
        ..._getCommonState(state),
        problem_types: action.payload,
      };
    case types.SET_COLLECTION_TYPES:
      return {
        ..._getCommonState(state),
        collection_types: action.payload,
      };
    case types.SET_EMPLOYEE:
      return {
        ..._getCommonState(state),
        employee_list: action.payload,
      };

    case types.SET_ADDRESS_BOOK:
      return {
        ..._getCommonState(state),
        address_books: action.payload,
      };

    case types.SET_POSITION:
      return {
        ..._getCommonState(state),
        positions: action.payload,
      };
    case types.SET_CUSTOM_CLEARENCE:
      return {
        ..._getCommonState(state),
        customClearence: action.payload,
      };
    case types.SET_CONTAINER_LIST:
      return {
        ..._getCommonState(state),
        container_list: action.payload,
      };
    default:
      return state;
  }
};

const _getCommonState = (state) => ({
  ...state,
  isLoading: false,
  errorMessage: "",
});

export default Index;

/**
 * action
 */

export const start_loading = () => ({
  type: common.START_LOADING,
});

export const stop_loading = () => ({
  type: common.STOP_LOADING,
});

export const set_error_message = (message = "") => ({
  type: common.SET_ERROR_MESSAGE,
  payload: message,
});

export const set_branch_tree = ({ child = [], parent = [] }) => ({
  type: types.SET_BRANCH_TREE,
  // payload: data,
  child,
  parent,
});

export const set_delete_custom_clearence = (data) => ({
  type: types.SET_TO_DELETE_CLEARNCE,
  payload: data,
});

export const set_destination = (data = []) => ({
  type: types.SET_DESTINATION,
  payload: data,
});

/**
 *
 * others
 */

export const set_currency = (data = []) => {
  return {
    type: types.SET_CURRENCY,
    payload: data,
  };
};

export const set_item_types = (data = []) => ({
  type: types.SET_ITEM_TYPES,
  payload: data,
});

export const set_express_types = (data = []) => ({
  type: types.SET_EXPRESS_TYPES,
  payload: data,
});

export const set_shipping_mode = (data = []) => ({
  type: types.SET_SHIPPING_MODES,
  payload: data,
});

export const set_estimated_time = (data = []) => ({
  type: types.SET_ESITMATED_TIME,
  payload: data,
});

export const set_carriers = (data = []) => ({
  type: types.SET_CARRIER,
  payload: data,
});

export const set_payment_methods = (data = []) => ({
  type: types.SET_PAYMENT_METHOD,
  payload: data,
});

export const set_problem_types = (data = []) => ({
  type: types.SET_PROBLEM_TYPES,
  payload: data,
});

export const set_transporations = (data = []) => ({
  type: types.SET_TRANSPORTATIONS,
  payload: data,
});

export const set_employee_list = (data = []) => ({
  type: types.SET_EMPLOYEE,
  payload: data,
});

export const set_address_book = (data = []) => ({
  type: types.SET_ADDRESS_BOOK,
  payload: data,
});

export const set_position = (data = []) => ({
  type: types.SET_POSITION,
  payload: data,
});

export const set_custom_clearence = (data = []) => ({
  type: types.SET_CUSTOM_CLEARENCE,
  payload: data,
});

export const set_collection_type = (data = []) => ({
  type: types.SET_COLLECTION_TYPES,
  payload: data,
});

export const set_container_list = (data = []) => ({
  type: types.SET_CONTAINER_LIST,
  payload: data,
});

export const set_delete_estimated_time = (data) => ({
  type: types.SET_TO_DELETE_ESTIMATED_TIME,
  payload: data,
});
