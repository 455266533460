import * as common from "../type";

export const initialState = {
  isLoading: false,
  errorMessage: "",

  branch_list: [],
  destination_list: [],

  country_list: [],
  region_list: [],
  district_list: [],
  township_list: [],

  itemtype_list: [],
  payment_type_list: [],
  shipping_type_list: [], // express type
  shipping_mode_list: [],
  interval_type_list: [],
  problem_type_list: [],
  sale_person_list: [],
  marketing_person_list: [],

  /**
   * scan type option list for waybill query filter
   */
  scantype_option: [],

  /**
   * creation on quotation interval group set
   */
  interval_list_sender: [],
  interval_list_receiver: [],

  /**
   * finance
   */
  collectiontype_list: [],

  /**
   * USER ROLE
   */
  user_role_list: [],

  payment_group: [],

  /**
   * Way Option
   */
  way_option: [],

  position: [],
  custom_clearences: [],

  sign_waybill_option: [],

  container_option: [],
};

/**
 * Reducer
 */
const Index = (state = initialState, action) => {
  switch (action.type) {
    case common.START_LOADING:
      return {
        ..._getCommonState(state),
        isLoading: true,
      };
    case common.STOP_LOADING:
      return {
        ..._getCommonState(state),
      };
    case common.SET_BRANCH_LIST: {
      return {
        ..._getCommonState(state),
        branch_list: action.payload,
      };
    }
    case common.SET_DESTINATION_LIST: {
      return {
        ..._getCommonState(state),
        destination_list: action.payload,
      };
    }
    case common.SET_REGION_LIST: {
      return {
        ..._getCommonState(state),
        region_list: action.payload,
      };
    }
    case common.SET_DISTRICT_LIST: {
      return {
        ..._getCommonState(state),
        district_list: action.payload,
      };
    }
    case common.SET_TOWNSHIP_LIST: {
      return {
        ..._getCommonState(state),
        township_list: action.payload,
      };
    }
    case common.SET_ITEMTYPE_LIST: {
      return {
        ..._getCommonState(state),
        itemtype_list: action.payload,
      };
    }
    case common.SET_PAYMENT_TYPE_LIST: {
      return {
        ..._getCommonState(state),
        payment_type_list: action.payload,
      };
    }
    case common.SET_SHIPPING_TYPE_LIST: {
      return {
        ..._getCommonState(state),
        shipping_type_list: action.payload,
      };
    }
    case common.SET_SHIPPING_MODE_LIST: {
      return {
        ..._getCommonState(state),
        shipping_mode_list: action.payload,
      };
    }
    case common.SET_INTERVAL_TYPE_LIST: {
      return {
        ..._getCommonState(state),
        interval_type_list: action.payload,
      };
    }
    case common.SET_INTERVAL_GROUP:
      return {
        ..._getCommonState(state),
        interval_list_sender: action.sender,
        interval_list_receiver: action.receiver,
      };
    case common.SET_USER_ROLE_OPTION:
      return {
        ..._getCommonState(state),
        user_role_list: action.payload,
      };
    case common.SET_PROBLEM_TYPE_LIST:
      return {
        ..._getCommonState(state),
        problem_type_list: action.payload,
      };
    case common.SET_SCAN_TYPE_OPTION:
      return {
        ..._getCommonState(state),
        scantype_option: action.payload,
      };
    case common.SET_SALE_PERSON_OPTION:
      return {
        ..._getCommonState(state),
        sale_person_list: action.payload,
      };
    case common.SET_MARKETING_PERSON_OPTION:
      return {
        ..._getCommonState(state),
        marketing_person_list: action.payload,
      };
    case common.SET_COUNTRY_LIST:
      return {
        ..._getCommonState(state),
        country_list: action.payload,
      };
    case common.SET_PAYMENT_GROUP:
      return {
        ..._getCommonState(state),
        payment_group: action.payload,
      };
    case common.SET_COLLECTION_TYPE:
      return {
        ..._getCommonState(state),
        collectiontype_list: action.payload,
      };

    case common.SET_WAY_OPTION:
      return {
        ..._getCommonState(state),
        way_option: action.payload,
      };
    case common.SET_POSTION_OPTION:
      return {
        ..._getCommonState(state),
        position: action.payload,
      };
    case common.SET_CUSTOM_CLEARENCE_OPTION:
      return {
        ..._getCommonState(state),
        custom_clearences: action.payload,
      };
    case common.SET_SIGN_WAYBILL:
      return {
        ..._getCommonState(state),
        sign_waybill_option: action.payload,
      };
    case common.SET_CONTAINER_OPTION:
      return {
        ..._getCommonState(state),
        container_option: action.payload,
      };

    default:
      return state;
  }
};

const _getCommonState = (state) => ({
  ...state,
  isLoading: false,
  errorMessage: "",
});

/**
 * action
 */

export const start_loading = () => ({
  type: common.START_LOADING,
});

export const stop_loading = () => ({
  type: common.STOP_LOADING,
});

export const set_branch_list = (data = []) => ({
  type: common.SET_BRANCH_LIST,
  payload: data,
});

export const set_destination_list = (data = []) => ({
  type: common.SET_DESTINATION_LIST,
  payload: data,
});

export const set_region_list = (data = []) => ({
  type: common.SET_REGION_LIST,
  payload: data,
});

export const set_district_list = (data = []) => ({
  type: common.SET_DISTRICT_LIST,
  payload: data,
});

export const set_township_list = (data = []) => ({
  type: common.SET_TOWNSHIP_LIST,
  payload: data,
});

export const set_itemtype_list = (data = []) => ({
  type: common.SET_ITEMTYPE_LIST,
  payload: data,
});

export const set_payment_type_list = (data = []) => ({
  type: common.SET_PAYMENT_TYPE_LIST,
  payload: data,
});

export const set_shipping_type_list = (data = []) => ({
  type: common.SET_SHIPPING_TYPE_LIST,
  payload: data,
});

export const set_shipping_mode_list = (data = []) => ({
  type: common.SET_SHIPPING_MODE_LIST,
  payload: data,
});

export const set_interval_type_list = (data = []) => ({
  type: common.SET_INTERVAL_TYPE_LIST,
  payload: data,
});

export const set_interval_group = (sender = [], receiver = []) => ({
  type: common.SET_INTERVAL_GROUP,
  sender,
  receiver,
});

export const set_role_option = (list = []) => ({
  type: common.SET_USER_ROLE_OPTION,
  payload: list,
});

export const set_problem_option = (list = []) => ({
  type: common.SET_PROBLEM_TYPE_LIST,
  payload: list,
});

export const set_scan_type_option = (list = []) => ({
  type: common.SET_SCAN_TYPE_OPTION,
  payload: list,
});

export const set_sale_person_list = (list = []) => ({
  type: common.SET_SALE_PERSON_OPTION,
  payload: list,
});

export const set_marketing_person_list = (list = []) => ({
  type: common.SET_MARKETING_PERSON_OPTION,
  payload: list,
});

export const set_country_list = (list = []) => ({
  type: common.SET_COUNTRY_LIST,
  payload: list,
});

export const set_payment_group = (list = []) => ({
  type: common.SET_PAYMENT_GROUP,
  payload: list,
});

export const set_collection_type = (list = []) => ({
  type: common.SET_COLLECTION_TYPE,
  payload: list,
});

export const set_way_option = (list = []) => ({
  type: common.SET_WAY_OPTION,
  payload: list,
});

export const set_position_option = (list = []) => ({
  type: common.SET_POSTION_OPTION,
  payload: list,
});

export const set_sign_waybill_option = (list = []) => ({
  type: common.SET_SIGN_WAYBILL,
  payload: list,
});

export const set_custom_clearence_option = (list = []) => ({
  type: common.SET_CUSTOM_CLEARENCE_OPTION,
  payload: list,
});

export const set_container_option = (list = []) => ({
  type: common.SET_CONTAINER_OPTION,
  payload: list,
});

export default Index;
