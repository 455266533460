import {
  set_error_message,
  start_loading,
  stop_loading,
  set_destination,
  set_branch_tree,
  set_address_book,
  set_employee_list,
  set_position,
  set_estimated_time,
  set_custom_clearence,
} from "../reducer.information";
import { component_reducer } from "../";
import { informationservice } from "../../service";
import { checkStatus } from "../util";
import { set_search_option } from "../reducer.document";

/**
 * if force === true , the api will be fetch again
 * else check condition
 * @param {*} force
 */

export const action_fetch_branch = (force = false) => {
  return async (dispatch, getState) => {
    const branch = getState()?.information?.branchParent || [];
    if (!force && Array.isArray(branch) && branch.length > 0) {
      return;
    }

    dispatch(start_loading());
    try {
      const response = await informationservice.getAllBranch(force);
      if (checkStatus(response)) {
        const body = await response.data;
        dispatch(set_branch_tree(body));
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const action_fetch_destination = () => {
  return async (dispatch, getState) => {
    dispatch(start_loading());
    try {
      const response = await informationservice.getDestination();
      if (checkStatus(response)) {
        const body = await response.data;
        dispatch(set_destination(body));
      } else dispatch(stop_loading());
    } catch (error) {
      dispatch(set_error_message(error.message));
    }
  };
};

/**
 * address book
 */
export const action_fetch_address_book = (force = false, payload = {}) => {
  return async (dispatch, getState) => {
    try {
      const data = getState()?.information?.address_books || [];
      if (!force && Array.isArray(data) && data.length > 0) {
        return;
      }

      let searchOption = getState().document.searchOption;

      searchOption = {
        ...searchOption,
        ...payload,
      };
      dispatch(set_search_option(searchOption));

      let response;
      if (force)
        response = await informationservice.getAddressBooklistSearch(
          searchOption
        );
      else response = await informationservice.getAddressBooklist();

      if (checkStatus(response)) {
        const body = await response.data;
        dispatch(set_address_book(body.docs || []));
        dispatch(component_reducer.set_total_count(body["totalDocs"] || 0));
      } else {
        console.log("..here");
      }
    } catch (error) {
      dispatch(set_error_message(error.message));
    }
  };
};

/**
 * employee
 */
export const action_fetch_employee = (force = false, data) => {
  return async (dispatch, getState) => {
    // const employee_list = getState()?.information?.employee_list || []
    // if (!force && Array.isArray(employee_list) && employee_list.length > 0) {
    //     return;
    // }

    dispatch(start_loading());

    try {
      const response = await informationservice.get_employee_list(force, data);
      if (checkStatus(response)) {
        const body = await response.data;
        dispatch(set_employee_list(body || []));
        //setTotalToLocation(body?.totalDocs || 0)
        dispatch(component_reducer.set_total_count(body["totalDocs"] || 0));
      } else {
        dispatch(stop_loading());
      }
    } catch (error) {
      dispatch(set_error_message(error.message));
    }
  };
};

/**
 * Employee update
 */
export const action_update_employee = (data, id) => {
  return async (dispatch, getState) => {
    dispatch(start_loading());
    try {
      const response = await informationservice.update_employee(data, id);
      if (checkStatus(response)) {
        const body = await response.data;
        dispatch(action_fetch_employee(true));
        dispatch(
          component_reducer.set_snack_bar_content({
            message: body.message || "successfully_updated",
            type: "success",
          })
        );
      } else {
        dispatch(
          component_reducer.set_snack_bar_content({
            message: "Oop something went wrong!",
            type: "error",
          })
        );
      }
    } catch (error) {
      dispatch(
        component_reducer.set_snack_bar_content({
          message: error.message,
          type: "error",
        })
      );
    }
  };
};

/**
 * Employee delete
 */
export const action_delete_employee = (id) => {
  return async (dispatch) => {
    dispatch(start_loading());
    try {
      const response = await informationservice.delete_employee(id);
      if (checkStatus(response)) {
        const body = await response.data;
        dispatch(action_fetch_employee(true));
        dispatch(
          component_reducer.set_snack_bar_content({
            message: body.message || "successfully_deleted",
            type: "success",
          })
        );
      } else {
        dispatch(
          component_reducer.set_snack_bar_content({
            message: "Oop something went wrong!",
            type: "error",
          })
        );
      }
    } catch (error) {
      dispatch(
        component_reducer.set_snack_bar_content({
          message: error.message,
          type: "error",
        })
      );
    }
  };
};

export const action_fetch_positions = (force = false) => {
  return async (dispatch, getState) => {
    dispatch(start_loading());

    const data = getState()?.information?.positions || [];
    if (!force && Array.isArray(data) && data.length > 0) {
      dispatch(stop_loading());
      return;
    }

    try {
      const response = await informationservice.get_position(force);
      if (checkStatus(response)) {
        const body = await response.data;
        dispatch(set_position(body || []));
      }
    } catch (error) {
      dispatch(set_error_message(error.message));
    }
  };
};

export const action_fetch_custom_clearence = (force = false) => {
  return async (dispatch, getState) => {
    dispatch(start_loading());

    const data = getState()?.information?.customClearences || [];
    if (!force && Array.isArray(data) && data.length > 0) {
      dispatch(stop_loading());
      return;
    }

    try {
      const response = await informationservice.get_custom_clearence(force);
      if (checkStatus(response)) {
        const body = await response.data;
        dispatch(set_custom_clearence(body || []));
      }
    } catch (error) {
      dispatch(set_error_message(error.message));
    }
  };
};

export const action_fetch_estimated_time = (force = false) => {
  return async (dispatch, getState) => {
    dispatch(start_loading());

    const data = getState()?.information?.estimatedTimes || [];
    if (!force && Array.isArray(data) && data.length > 0) {
      dispatch(stop_loading());
      return;
    }

    try {
      const response = await informationservice.get_estimated_time(force);
      if (checkStatus(response)) {
        const body = await response.data;
        dispatch(set_estimated_time(body || []));
      }
    } catch (error) {
      dispatch(set_error_message(error.message));
    }
  };
};

export const action_create_position = (data) => {
  return async (dispatch, getState) => {
    dispatch(start_loading());
    try {
      const response = await informationservice.create_position(data);
      if (checkStatus(response)) {
        const body = await response.data;
        dispatch(action_fetch_positions(true));
        dispatch(
          component_reducer.set_snack_bar_content({
            message: body.message || "successfully_created",
            type: "success",
          })
        );
      } else {
        dispatch(
          component_reducer.set_snack_bar_content({
            message: "Oop something went wrong!",
            type: "error",
          })
        );
      }
    } catch (error) {
      dispatch(
        component_reducer.set_snack_bar_content({
          message: error.message,
          type: "error",
        })
      );
    }
  };
};

export const action_create_custom_clearence = (data) => {
  return async (dispatch, getState) => {
    dispatch(start_loading());
    try {
      const response = await informationservice.create_custom_clearence(data);
      if (checkStatus(response)) {
        const body = await response.data;
        dispatch(action_fetch_custom_clearence(true));
        dispatch(
          component_reducer.set_snack_bar_content({
            message: body.message || "successfully_created",
            type: "success",
          })
        );
      } else {
        dispatch(
          component_reducer.set_snack_bar_content({
            message: "Oop something went wrong!",
            type: "error",
          })
        );
      }
    } catch (error) {
      dispatch(
        component_reducer.set_snack_bar_content({
          message: error.message,
          type: "error",
        })
      );
    }
  };
};

export const action_create_estimated_time = (data) => {
  return async (dispatch, getState) => {
    dispatch(start_loading());
    try {
      const response = await informationservice.create_estimated_time(data);
      if (checkStatus(response)) {
        const body = await response.data;
        dispatch(action_fetch_estimated_time(true));
        dispatch(
          component_reducer.set_snack_bar_content({
            message: body.message || "successfully_created",
            type: "success",
          })
        );
      } else {
        dispatch(
          component_reducer.set_snack_bar_content({
            message: "Oop something went wrong!",
            type: "error",
          })
        );
      }
    } catch (error) {
      dispatch(
        component_reducer.set_snack_bar_content({
          message: error.message,
          type: "error",
        })
      );
    }
  };
};

export const action_update_custom_clearence = (data) => {
  return async (dispatch, getState) => {
    dispatch(start_loading());
    try {
      const response = await informationservice.update_custom_clearence(data);
      if (checkStatus(response)) {
        const body = await response.data;
        dispatch(action_fetch_custom_clearence(true));
        dispatch(
          component_reducer.set_snack_bar_content({
            message: body.message || "successfully_created",
            type: "success",
          })
        );
      } else {
        dispatch(
          component_reducer.set_snack_bar_content({
            message: "Oop something went wrong!",
            type: "error",
          })
        );
      }
    } catch (error) {
      dispatch(
        component_reducer.set_snack_bar_content({
          message: error.message,
          type: "error",
        })
      );
    }
  };
};

export const action_update_estimated_time = (data) => {
  return async (dispatch, getState) => {
    dispatch(start_loading());
    try {
      const response = await informationservice.update_estimated_time(data);
      if (checkStatus(response)) {
        const body = await response.data;
        dispatch(action_fetch_estimated_time(true));
        dispatch(
          component_reducer.set_snack_bar_content({
            message: body.message || "successfully_created",
            type: "success",
          })
        );
      } else {
        dispatch(
          component_reducer.set_snack_bar_content({
            message: "Oop something went wrong!",
            type: "error",
          })
        );
      }
    } catch (error) {
      dispatch(
        component_reducer.set_snack_bar_content({
          message: error.message,
          type: "error",
        })
      );
    }
  };
};

export const action_delete_custom_clearence = (id) => {
  return async (dispatch, getState) => {
    dispatch(start_loading());
    try {
      const response = await informationservice.delete_custom_clearence(id);
      if (checkStatus(response)) {
        const body = await response.data;
        dispatch(action_fetch_custom_clearence(true));
        dispatch(
          component_reducer.set_snack_bar_content({
            message: body.message || "successfully deleted",
            type: "success",
          })
        );
      }
    } catch (error) {
      dispatch(
        component_reducer.set_snack_bar_content({
          message: error.message,
          type: "error",
        })
      );
    }
  };
};

export const action_delete_position = (position_id) => {
  return async (dispatch, getState) => {
    dispatch(start_loading());
    try {
      const response = await informationservice.delete_position(position_id);
      if (checkStatus(response)) {
        const body = await response.data;
        dispatch(action_fetch_positions(true));
        dispatch(
          component_reducer.set_snack_bar_content({
            message: body.message || "successfully deleted",
            type: "success",
          })
        );
      }
    } catch (error) {
      dispatch(
        component_reducer.set_snack_bar_content({
          message: error.message,
          type: "error",
        })
      );
    }
  };
};

export const action_update_position = (data) => {
  return async (dispatch, getState) => {
    dispatch(start_loading());
    try {
      const response = await informationservice.update_position(data);
      if (checkStatus(response)) {
        const body = await response.data;
        dispatch(action_fetch_positions(true));
        dispatch(
          component_reducer.set_snack_bar_content({
            message: body.message || "successfully_created",
            type: "success",
          })
        );
      }
    } catch (error) {
      dispatch(
        component_reducer.set_snack_bar_content({
          message: error.message,
          type: "error",
        })
      );
    }
  };
};
