import { getPaginationQuery, httpService } from ".";

/**
 * Branch maintenance
 */

export async function getBranch({ branches = [] }) {
  let url = `/branches/getbranch?`;

  if (Array.isArray(branches) && branches.length > 0) {
    branches.forEach((b) => {
      url += `branches[]=${b}&`;
    });
  }

  return await httpService.get(url);
}

export async function getBranchTree() {
  return await httpService.get("/branch/get-branch-tree");
}

export async function getAllBranch(refresh = false) {
  let query = "/branch/get-all";
  if (refresh) query += "-refresh";
  return await httpService.get(query);
}

export async function getDestination() {
  return await httpService.get("/destinations/getdestination");
}

/**
 * address book
 */
export async function getAddressBooklist() {
  return await httpService.get(`/address-book/get-all${getPaginationQuery()}`);
}

export async function getAddressBooklistSearch(data) {
  return await httpService.post(
    `/address-book/get-all-refresh?${getPaginationQuery()}`,
    data
  );
}

/**
 * other maintenance
 *
 */
export async function getItemTypes(refresh) {
  return await httpService.get(
    "/itemtypes/get-all" + (refresh ? "-refresh" : "")
  );
}

export async function getExpressTypes(refresh) {
  return await httpService.get(
    "/shipping-type/get-all" + (refresh ? "-refresh" : "")
  );
}

export async function getShippingMode(refresh) {
  return await httpService.get(
    "/shipping-mode/get-all" + (refresh ? "-refresh" : "")
  );
}

export async function getCarriers() {
  return await httpService.get("/carriers/getcarrier");
}

export async function getPaymentMethod(refresh) {
  return await httpService.get(
    "/payment-type/get-all" + (refresh ? "-refresh" : "")
  );
}

export async function getProblemTypes(update = false) {
  return await httpService.get(
    "/problems/get-problem-type" + (update ? "-update" : "")
  );
}

export async function getContainers(refresh) {
  return await httpService.get(
    "/container/get-all" + (refresh ? "-refresh" : "")
  );
}

export async function getTrucks(refresh) {
  return await httpService.get("/truck/get-all" + (refresh ? "-refresh" : ""));
}

export async function getCurrency(refresh) {
  return await httpService.get(
    "/currency/get-all" + (refresh ? "-refresh" : "")
  );
}

/**
 * post and create data
 */

/**
 * Create currency
 */
export async function createCurrency(data) {
  return await httpService.post("/currency/create", data);
}

/**
 * Update currency
 */
export async function updateCurrency(data) {
  return await httpService.patch("/currency/update", data);
}

/**
 * Delete currency
 */
export async function deleteCurrency(id) {
  return await httpService.delete("/currency/delete/" + id);
}

/**
 * branch and destination
 */
export async function create_branch(data) {
  return await httpService.post("/branch/create", data);
}

export async function update_branch(data) {
  return await httpService.post("/branch/update", data);
}

export async function changeStatus(id, data) {
  return await httpService.patch(`/branch/${id}`, data);
}

export async function delete_branch(id) {
  return await httpService.delete(`/branch/${id}`);
}

export async function create_destination(data) {
  return await httpService.post("/destinations/createdestination", data);
}

export async function create_address_book(data) {
  return await httpService.post("/address-book/create", data);
}

export async function update_info_address_book(data) {
  return await httpService.patch("/address-book/update-info", data);
}

export async function update_address_address_book(data) {
  return await httpService.patch("/address-book/update-address", data);
}

/**
 * quotation
 */
export async function getInterval() {
  return await httpService.get("/quotations/getallintervals");
}

export async function create_interval(data) {
  return await httpService.post("/quotations/createinterval", data);
}

export async function get_quotation_interval() {
  return await httpService.get("/quotations/get-quotation-interval");
}

export async function create_quotation_interval(data) {
  return await httpService.post("/quotations/create-quotation-interval", data);
}

export async function create_quotation_rule(quotation_id, data) {
  return await httpService.post(
    `/quotations/create-quotation-rule?quotation=${quotation_id}`,
    data
  );
}

export async function delete_test(id) {
  return id ? true : false;
}

/**
 * CUSTOMER INFORMATION
 */
/** default is VIP */
export async function get_customer_list(type) {
  let query = "/customers";
  if (type && ["self", "vip"].includes(type)) query += `?type=${type}`;
  return await httpService.get(query);
}

/**
 * creating VIP customer
 * @param {*} data
 */
export async function create_vip_customer(data) {
  return await httpService.post("/customers/vip-customer-register", data);
}

/**
 * creating item type
 */

export async function create_item_type(data) {
  return await httpService.post("/itemtypes/create", data);
}

/**
 * updating item type
 */
export async function update_item_type(data) {
  return await httpService.patch(`/itemtypes/update`, data);
}

/**
 * deleting item type
 */
export async function delete_item_type(id) {
  return await httpService.delete(`/itemtypes/delete/${id}`);
}

/**
 * creating trucks
 */
export async function create_truck(data) {
  return await httpService.post("/truck/create", data);
}

/**
 * container
 * @param {c} data
 * @returns
 */
export async function create_container(data) {
  return await httpService.post(`/container/create`, data);
}

/**
 * create express type
 */
export async function create_express_type(data) {
  return await httpService.post("/shipping-type/create", data);
}

/**
 * update express type
 */
export async function update_expredd_type(data) {
  return await httpService.patch(`/shipping-type/update`, data);
}

/**
 * delete express type
 */
export async function delete_express_type(id) {
  return await httpService.delete(`/shipping-type/delete/${id}`);
}

/**
 * create shipping mode
 */
export async function create_shipping_mode(data) {
  return await httpService.post("/shipping-mode/create", data);
}

/**
 * updating shipping mode
 */
export async function update_shipping_mode(data) {
  return await httpService.patch(`/shipping-mode/update`, data);
}

/**
 * deleting shipping mode
 */
export async function delete_shipping_mode(id) {
  return await httpService.delete(`/shipping-mode/delete/${id}`);
}

/**
 * creating payment type
 */
export async function create_new_payment_type(data) {
  return await httpService.post("/payment-type/create", data);
}

/**
 * updating payment type
 */
export async function update_payment_type(data) {
  return await httpService.patch(`/payment-type/update`, data);
}

/**
 * delete payment type
 */
export async function delete_payment_type(id) {
  return await httpService.delete(`/payment-type/delete/${id}`);
}

/**
 * create problem type
 */
export async function create_new_problem_type(data) {
  return await httpService.post("/problems/create-problem-type", data);
}

/**
 * truck list
 */
export async function get_truck_list() {
  return await httpService.get("/truck-and-way/truck-list");
}

/**
 * employee
 */
export async function get_employee_list(refresh, data) {
  let query = "/employee/get-all";
  if (refresh) query += "-refresh";

  return await httpService.post(query, data);
}

export async function get_sale_employee_option() {
  return await httpService.post("/employee/get-sale-employee-option");
}

export async function create_employee(data) {
  return await httpService.post("/employee/create", data);
}

export async function get_employee_option() {
  return await httpService.get("/employee/get-option");
}

export async function update_employee(data, id) {
  return await httpService.put(`/employee/update/${id}`, data);
}

export async function delete_employee(id) {
  return await httpService.delete(`/employee/delete/${id}`);
}

/**
 * position
 */
export async function get_position_opton() {
  return await httpService.get("/position/get-option");
}

export async function get_custom_clearence_opton() {
  return await httpService.get("/custom_clearence/get-option");
}

export async function get_position(force) {
  return await httpService.get(`/position/get-all${force ? "-refresh" : ""}`);
}

export async function get_custom_clearence(force) {
  return await httpService.get(
    `/custom_clearence/get-all${force ? "-refresh" : ""}`
  );
}

export async function get_estimated_time(force) {
  return await httpService.get(
    `/estimated_time/get-all${force ? "-refresh" : ""}`
  );
}

export async function create_position(data) {
  return await httpService.post("/position/create", data);
}

export async function create_custom_clearence(data) {
  return await httpService.post("/custom_clearence/create", data);
}

export async function create_estimated_time(data) {
  return await httpService.post("/estimated_time/create", data);
}

export async function update_custom_clearence(data) {
  return await httpService.put("/custom_clearence/update", data);
}

export async function update_estimated_time(data) {
  return await httpService.put("/estimated_time/update", data);
}

export async function delete_position(position_id) {
  return await httpService.delete(`/position/${position_id}`);
}

export async function delete_custom_clearence(id) {
  return await httpService.post(`/custom_clearence/delete`, { id: id });
}

export async function delete_estimated_time(id) {
  return await httpService.post(`/estimated_time/delete`, { id: id });
}

export async function update_position(data) {
  return await httpService.put("/position/update", data);
}

/**
 * collection
 */
export async function get_collection_type(force) {
  return await httpService.get(
    `/collection-type/get-all${force ? "-refresh" : ""}`
  );
}

export async function create_collection_type(data) {
  return await httpService.post("/collection-type/create", data);
}
