export const START_LOADING = "START_LOADING";

export const STOP_LOADING = "STOP_LOADING";

export const SET_ERROR_MESSAGE = "SET_ERROR_MESSAGE";

// For dynamic tabbar
export const ADD_TAB = "ADD_TAB";
export const DEL_TAB = "DEL_TAB";

/**
 * options
 */
export const SET_COUNTRY_LIST = "SET_COUNTRY_LIST";
export const SET_CUSTOM_CLEARENCE_OPTION = "SET_CUSTOM_CLEARENCE_OPTION";

export const SET_REGION_LIST = "SET_REGION_LIST";

export const SET_DISTRICT_LIST = "SET_DISTRICT_LIST";

export const SET_TOWNSHIP_LIST = "SET_TOWNSHIP_LIST";

export const SET_BRANCH_LIST = "SET_BRANCH_LIST";

export const SET_DESTINATION_LIST = "SET_DESTINATION_LIST";

export const SET_ITEMTYPE_LIST = "SET_ITEMTYPE_LIST";

export const SET_PAYMENT_TYPE_LIST = "SET_PAYMENT_TYPE_LIST";

export const SET_SHIPPING_TYPE_LIST = "SET_SHIPPING_TYPE_LIST";

export const SET_SHIPPING_MODE_LIST = "SET_SHIPPING_MODE_LIST";

export const SET_INTERVAL_TYPE_LIST = "SET_INTERVAL_TYPE_LIST";

export const SET_INTERVAL_GROUP = "SET_INTERVAL_GROUP";

export const SET_USER_ROLE_OPTION = "SET_USER_ROLE_OPTION";

export const SET_PROBLEM_TYPE_LIST = "SET_PROBLEM_TYPE_LIST";

export const SET_SCAN_TYPE_OPTION = "SET_SCAN_TYPE_OPTION";

export const SET_TRUCK_UTIL_OPTION = "SET_TRUCK_UTIL_OPTION";

export const SET_SALE_PERSON_OPTION = "SET_SALE_PERSON_OPTION";

export const SET_MARKETING_PERSON_OPTION = "SET_MARKETING_PERSON_OPTION";

export const SET_TRUCK_OPTION = "SET_TRUCK_OPTION";

export const SET_PAYMENT_GROUP = "SET_PAYMENT_GROUP";

export const SET_COLLECTION_TYPE = "SET_COLLECTION_TYPE";

export const SET_WAY_OPTION = "SET_WAY_OPTION";

export const SET_POSTION_OPTION = "SET_POSTION_OPTION";

export const SET_SIGN_WAYBILL = "SET_SIGN_WAYBILL";

export const SET_CONTAINER_OPTION = "SET_CONTAINER_OPTION";
